import { useState } from 'react';

import useApi from '../../../hooks/useApi';

import APIError from '../../general/APIError';
import Button from '../../general/input/Button';
import LoadingOverlay from '../../general/LoadingOverlay';
import Bookmark from '../../navigation/Bookmark';
import ExportMOPModal from './modal/ExportMOPModal';
import MOPGeneralView from './MOPGeneralView';
import MOPSignature from './MOPSignature';
import MOPSteps from './MOPSteps';
import { useIsAuthenticated } from '@azure/msal-react';
import { faArrowLeft, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/ReviewMOP.module.scss';
import { useSnackbar } from 'notistack';

const ReviewMOP = () => {
    const match = useMatch({
        path: '/mop/review/:id/:validationString',
        end: false
    });

    const isAuthenticated = useIsAuthenticated();
    const { enqueueSnackbar } = useSnackbar();

    const [{ data: mop, loading: loadingMOP, error: mopError }] = useApi(
        `/mop/form/${match?.params?.id}/${match?.params?.validationString}`,
        'GET',
        { manual: false }
    );

    const [{ data: savedSignature, loading: savingSignature }, saveSignature] =
        useApi(
            `/mop/review/${match?.params?.id}/${match?.params?.validationString}`,
            'PUT',
            {
                manual: true
            }
        );

    const navigate = useNavigate();
    const handleReturnToMOP = () => navigate('/mop/forms');

    const [signature, setSignature] = useState(null);
    const [showExport, setShowExport] = useState(false);

    const handleSaveSignature = (data) => {
        setSignature(data);
        saveSignature({
            data: {
                signature: data?.replace?.('data:image/png;base64,', '')
            }
        }).then().catch(err => {
            console.error(err);
            enqueueSnackbar('Couldn\'t save signature, please try again.', {
                variant: 'error',
                autoHideDuration: 5000
            });
        });
    };

    const handleOpenExport = () => setShowExport(true);
    const handleCloseExport = () => setShowExport(false);

    return (
        <div className={styles.container}>
            {loadingMOP ? (
                <div className={styles.loadingContainer}>
                    <LoadingOverlay label="Loading MOP..." />
                </div>
            ) : mopError ? (
                <APIError
                    error={mopError}
                    defaultMesage="Could not retrieve the requested MOP. Please refresh and try again."
                    defaultTitle="Error Encountered"
                    actions={[
                        {
                            variant: 'text',
                            icon: faArrowLeft,
                            label: 'Back to MOPs',
                            linkTo: '/mop/forms'
                        }
                    ]}
                />
            ) : savedSignature ? (
                <div className={styles.savedContainer}>
                    <h3>Review Submitted</h3>
                    <p>
                        Thank you for reviewing this MOP. If you would like to
                        download a copy for your records, please select "Export
                        MOP" below.
                    </p>
                    <Button
                        icon={faFileExport}
                        label="Export MOP"
                        onClick={handleOpenExport}
                    />
                    {isAuthenticated && (
                        <Button
                            icon={faArrowLeft}
                            label="Back to MOPs"
                            type="secondary"
                            linkTo="/mop/forms"
                        />
                    )}
                    {showExport && <div>Export</div>}
                </div>
            ) : mop.mopSignatures?.[0]?.signature ? (
                <div className={styles.savedContainer}>
                    <h3>Review Submitted</h3>
                    <p>This MOP has already been reviewed.</p>
                    {isAuthenticated && (
                        <Button
                            icon={faArrowLeft}
                            label="Back to MOPs"
                            type="secondary"
                            linkTo="/mop/forms"
                        />
                    )}
                </div>
            ) : mop.statusId > 2 ? (
                <div className={styles.savedContainer}>
                    <h3>Review Expired</h3>
                    <p>
                        The window for reviewing this MOP has closed or your
                        signature is no longer required.
                    </p>
                    {isAuthenticated && (
                        <Button
                            icon={faArrowLeft}
                            label="Back to MOPs"
                            type="secondary"
                            linkTo="/mop/forms"
                        />
                    )}
                </div>
            ) : (
                <div className={styles.form}>
                    <Bookmark
                        panelContainerClass={styles.bookmarkPanelContainer}
                        overlayBreakpoint={1300}
                    >
                        <Bookmark.List>
                            <Bookmark.Section>
                                <Bookmark.Option>
                                    General Information
                                </Bookmark.Option>
                                <Bookmark.Option>Steps</Bookmark.Option>
                                <Bookmark.Option>Review & Sign</Bookmark.Option>
                            </Bookmark.Section>
                            {isAuthenticated && (
                                <Bookmark.Footer>
                                    <Bookmark.Link
                                        icon={faArrowLeft}
                                        handleClick={handleReturnToMOP}
                                    >
                                        Back to MOPs
                                    </Bookmark.Link>
                                </Bookmark.Footer>
                            )}
                        </Bookmark.List>
                        <Bookmark.Panel>
                            <MOPGeneralView mop={mop} label="Review MOP" />
                        </Bookmark.Panel>
                        <Bookmark.Panel>
                            <MOPSteps fields={mop.mopSteps} readonly />
                        </Bookmark.Panel>
                        <Bookmark.Panel>
                            <div className={styles.mopSignatureReviewContainer}>
                                <MOPSignature
                                    handleSave={handleSaveSignature}
                                    signature={{
                                        signature: signature,
                                        name: mop?.mopSignatures?.[0]
                                            ?.responsibleParty
                                    }}
                                    loading={savingSignature}
                                    submitted={savedSignature}
                                />
                            </div>
                        </Bookmark.Panel>
                    </Bookmark>
                </div>
            )}
            {showExport && (
                <ExportMOPModal
                    handleClose={handleCloseExport}
                    selected={match?.params?.id}
                    autoExport
                    message={'Your export should begin shortly.'}
                />
            )}
        </div>
    );
};

export default ReviewMOP;
