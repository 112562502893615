import { useRef } from 'react';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/mop/modal/NewMOPStepModal.module.scss';

const NewMOPStepModal = ({ handleClose, handleCreateStep }) => {
    const submitButtonRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control, setError, clearErrors, ...methods } =
        useForm({
            mode: 'onSubmit',
            reValidateMode: 'onSubmit',
            criteriaMode: 'all',
            defaultValues: {
                assignedTo: '',
                description: '',
                backoutDescription: ''
            }
        });

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    const handleCreate = (data) => {
        enqueueSnackbar({
            variant: 'success',
            message: 'Step Added',
            autoHideDuration: 1500
        });
        handleCreateStep(data);
    };

    return (
        <Modal open dismissable handleClose={handleClose}>
            <Modal.Title icon={faPlus}>New Step</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={(e) => {
                            handleSubmit(handleCreate)(e);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="assignedTo"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel label="Assigned To">
                                        <TextInput
                                            autoFocus
                                            value={value}
                                            placeholder="Assigned To"
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="description"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel label="Description">
                                        <TextInput
                                            lines={5}
                                            value={value}
                                            placeholder="Description"
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="backoutDescription"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel label="Backout Process">
                                        <TextInput
                                            lines={5}
                                            value={value}
                                            placeholder="Backout Process"
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button label="Create" onClick={handleSubmitClicked} />
            </Modal.Actions>
        </Modal>
    );
};

export default NewMOPStepModal;
