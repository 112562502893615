import useApi from '../../../hooks/useApi';

import APIError from '../../general/APIError';
import InputLabel from '../../general/input/InputLabel';
import LoadingOverlay from '../../general/LoadingOverlay';
import Bookmark from '../../navigation/Bookmark';
import MOPGeneralView from './MOPGeneralView';
import MOPSignatureReview from './MOPSignatureReview';
import MOPSteps from './MOPSteps';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/ViewMOP.module.scss';

const ViewMOP = () => {
    const match = useMatch({
        path: '/mop/view/:id',
        end: false
    });

    const [{ data: mop, loading: loadingMOP, error: mopError }] = useApi(
        `/mop/form/${match?.params?.id}`,
        'GET',
        { manual: false }
    );

    const navigate = useNavigate();
    const handleReturnToMOP = () => navigate('/mop/forms');

    return (
        <div className={styles.container}>
            {loadingMOP ? (
                <div className={styles.loadingContainer}>
                    <LoadingOverlay label="Loading MOP..." />
                </div>
            ) : mopError ? (
                <APIError
                    error={mopError}
                    defaultMessage={
                        'Could not retrieve the requested MOP. Please refresh and try again.'
                    }
                    defaultTitle={'Error Encountered'}
                    actions={[
                        {
                            variant: 'text',
                            icon: faArrowLeft,
                            label: 'Back to MOPs',
                            linkto: '/mop/forms'
                        }
                    ]}
                />
            ) : (
                <div className={styles.form}>
                    <Bookmark
                        panelContainerClass={styles.bookmarkPanelContainer}
                        overlayBreakpoint={1300}
                    >
                        <Bookmark.List>
                            <Bookmark.Section>
                                <Bookmark.Option>
                                    General Information
                                </Bookmark.Option>
                                <Bookmark.Option>Steps</Bookmark.Option>
                                <Bookmark.Option>Signatures</Bookmark.Option>
                            </Bookmark.Section>
                            <Bookmark.Footer>
                                <Bookmark.Link
                                    icon={faArrowLeft}
                                    handleClick={handleReturnToMOP}
                                >
                                    Back to MOPs
                                </Bookmark.Link>
                            </Bookmark.Footer>
                        </Bookmark.List>
                        <Bookmark.Panel>
                            <MOPGeneralView mop={mop} />
                        </Bookmark.Panel>
                        <Bookmark.Panel>
                            <MOPSteps
                                fields={mop.mopSteps?.sort(
                                    (a, b) => a.sequence - b.sequence
                                )}
                                readonly
                            />
                            <div className={styles.signatureContainer}>
                                <InputLabel label="Pre-MOP Walk Signature">
                                    {mop?.walkSignature && (
                                        <img
                                            srcSet={`data:image/png;base64,${mop.walkSignature} 2x`}
                                            alt=""
                                        />
                                    )}
                                </InputLabel>
                                <InputLabel
                                    label={
                                        mop?.backoutInitiated
                                            ? 'Backout Signature'
                                            : 'MOP Signature'
                                    }
                                >
                                    {(mop?.signature ||
                                        mop?.backoutSignature) && (
                                        <img
                                            srcSet={`data:image/png;base64,${
                                                mop.backoutInitiated
                                                    ? mop.backoutSignature
                                                    : mop.signature
                                            } 2x`}
                                            alt=""
                                        />
                                    )}
                                </InputLabel>
                            </div>
                        </Bookmark.Panel>
                        <Bookmark.Panel>
                            <div className={styles.mopSignatureReviewContainer}>
                                <MOPSignatureReview
                                    readonly={mop.statusId > 2}
                                    fields={mop.mopSignatures}
                                    mop={mop}
                                />
                            </div>
                        </Bookmark.Panel>
                    </Bookmark>
                </div>
            )}
        </div>
    );
};

export default ViewMOP;
