import useFetch from '../../../../hooks/useFetch';

import { isNullOrEmpty } from '../../../../js/services/validation';
import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/mop/modal/ExportMOPModal.module.scss';

const ExportMOPModal = ({
    handleClose,
    filters,
    selected,
    message,
    autoExport
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading }, exportMops] = useFetch('/mop/export', 'GET', {
        manual: !autoExport ?? true
    });

    const handleExport = () => {
        const buildUrl = () => {
            let url = `/mop/export`;
            let finalFilters = filters;

            if (!isNullOrEmpty(selected))
                finalFilters = {
                    ...finalFilters,
                    MopIds: {
                        value: selected?.map?.((s) => s.id),
                        getFilter: (value) => ({ MopIds: value })
                    }
                };

            if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
                finalFilters = {
                    ...finalFilters,
                    Timezone: {
                        value: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        getFilter: (value) => ({ Timezone: value })
                    }
                };
            }

            if (Object.keys(finalFilters).length === 0) return url;

            let filterObj = finalFilters
                ? Object.keys(finalFilters)
                      ?.filter(
                          (filter) =>
                              !!finalFilters?.[filter]?.getFilter?.(
                                  finalFilters?.[filter]?.value
                              )
                      )
                      .reduce(
                          (acc, cur) => ({
                              ...acc,
                              ...finalFilters[cur].getFilter?.(
                                  finalFilters?.[cur]?.value
                              )
                          }),
                          {}
                      )
                : {};

            url += `?filter=${JSON.stringify(filterObj)}`;

            return url;
        };

        exportMops({
            url: buildUrl()
        })
            .then((res) => res.blob())
            .then((data) => {
                let today = new Date();
                let month = today.getMonth() + 1;
                let day = today.getDate();
                let year = today.getFullYear();
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.download = `${month}-${day}-${year} MOP Export.pdf`;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
                handleClose();
            })
            .catch((err) => {
                console.log('err', err);
                enqueueSnackbar('Failed to export data.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            });
    };

    return (
        <Modal open blocking handleClose={handleClose}>
            <Modal.Title icon={faFileExport}>Export MOPs</Modal.Title>
            <Modal.Body>
                <p className={styles.confirmationMessage}>
                    {message ?? 'Are you sure you want to export these MOPs?'}
                </p>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleExport}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ExportMOPModal;
