const isDate = (obj) => obj && obj.constructor === Date;

const isEmptyObject = (obj) =>
    obj && Object.keys(obj)?.length === 0 && obj.constructor === Object;

const isNullOrEmpty = (value) => {
    if (!value) return true;

    switch (Object.prototype.toString.call(value)) {
        case '[object String]':
            return value.replace(/\s/gi, '') === '';
        case '[object Array]':
            return !value.length || value.length === 0;
        case '[object Object]':
            return Object.keys(value)?.length === 0;
        default:
            return false;
    }
};

const isValidJSON = (obj) =>
    obj && (obj.constructor === String || obj.constructor === Number);

const objectEqualityCompare = (obj, comparatorObj, ignoreList) => {
    if (!obj || !comparatorObj) return false;
    return Object.keys(obj).every?.(
        (o) =>
            ignoreList.includes(o) ||
            (comparatorObj[o] !== undefined &&
                (Array.isArray(obj[o])
                    ? arrayEqualityCompare(obj[o], comparatorObj[o])
                    : typeof obj[o] === 'object' && obj[o] !== null
                    ? objectEqualityCompare(obj[o], comparatorObj[o])
                    : comparatorObj[o] === obj[o]))
    );
};

const arrayEqualityCompare = (array, comparatorArray) =>
    array?.every?.((a) => comparatorArray.includes(a));

export {
    isDate,
    isEmptyObject,
    isNullOrEmpty,
    isValidJSON,
    objectEqualityCompare,
    arrayEqualityCompare
};
