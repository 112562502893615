import { useState } from 'react';

import TextInput from '../../general/input/TextInput';
import { faCheck, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/apps/mop/MOPStepCard.module.scss';

const MOPStepCard = ({
    step,
    descriptionKey = 'description',
    completedKey = 'completed',
    commentKey = 'mopComments',
    handleStepChecked,
    handleComments
}) => {
    const [showComments, setShowComments] = useState(false);

    const _handleComments = (e) => {
        handleComments(step?.id, e.target.value);
    };

    return (
        <div className={styles.stepContainer}>
            <div className={styles.sequence}>#{step.sequence + 1}</div>
            <div className={styles.stepBodyContainer}>
                <div className={styles.stepHeader}>
                    <FontAwesomeIcon
                        icon={faUser}
                        color="var(--xlight-text-color)"
                    />
                    <p>{step.assignedTo}</p>
                </div>
                <div className={styles.stepBody}>{step[descriptionKey]}</div>
                {step.preMopComments && (
                    <div className={styles.stepBody}>
                        <b>Pre-MOP Comments: </b>
                        {step.preMopComments}
                    </div>
                )}
                {step.mopComments && (
                    <div className={styles.stepBody}>
                        <b>MOP Comments: </b>
                        {step.mopComments}
                    </div>
                )}
                <div className={styles.stepComments}>
                    {showComments || step?.[commentKey] ? (
                        <TextInput
                            lines={3}
                            placeholder="Comments"
                            onChange={_handleComments}
                            value={step?.[commentKey] ?? ''}
                            autoFocus
                        />
                    ) : (
                        <div
                            onClick={setShowComments.bind(this, true)}
                            className={styles.addComments}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            <p>Add Comments</p>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={[
                    styles.checkboxContainer,
                    step[completedKey] ? styles.completed : styles.incomplete
                ].join(' ')}
                onClick={handleStepChecked?.bind(this, step.id)}
            >
                <FontAwesomeIcon icon={faCheck} />
            </div>
        </div>
    );
};

export default MOPStepCard;
