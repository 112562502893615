import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import { isNullOrEmpty } from '../../../js/services/validation';
import APIError from '../../general/APIError';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import LogoLoader from '../../general/LogoLoader';
import MOPSignature from './MOPSignature';
import MOPStepCard from './MOPStepCard';
import { faArrowLeft, faList, faUserMinus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/mop/WalkMOP.module.scss';
import MOPContacts from './MOPContacts';

const WalkMOP = () => {
    const match = useMatch({
        path: '/mop/walk/:id',
        end: false
    });

    const { enqueueSnackbar } = useSnackbar();

    const [mopSteps, setMopSteps] = useState([]);
    const [signature, setSignature] = useState(0);
    const [contacts, setContacts] = useState(false);

    const [{ data: mop, loading: loadingMOP, error: mopError }, getMOP] =
        useApi(`/mop/form/${match?.params?.id}`, 'GET', { manual: true });

    const [{ data: savedMOP, loading: loadingSubmit }, submitMOP] = useApi(
        `/mop/form/walk`,
        'PUT',
        { manual: true }
    );

    const { displayName } = useUser();

    useEffect(() => {
        getMOP()
            .then((mop) => {
                setMopSteps(
                    mop?.mopSteps?.map?.((ms) => ({
                        ...ms,
                        completed: isNullOrEmpty(ms.description)
                            ? true
                            : ms.completed
                    }))
                );
            })
            .catch((err) => {
                console.error(err);
            });
    }, []); //eslint-disable-line

    const handleStepChecked = (id) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                completed: id === ms.id ? !ms.completed : ms.completed
            }))
        );
    };

    const handleStepComments = (id, value) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                preMopComments: id === ms.id ? value : ms.preMopComments
            }))
        );
    };

    const handleSaveSignature = (signature) => {
        setSignature(signature);
        submitMOP({
            url: `/mop/walk/${match?.params?.id}`,
            data: {
                signature: signature?.replace?.('data:image/png;base64,', ''),
                mopSteps: mopSteps?.map?.((ms) => ({
                    ...ms,
                    comments: ms.preMopComments
                }))
            }
        })
            .then(() => {})
            .catch((err) => {
                console.error(err);
                setSignature(null);
                enqueueSnackbar(
                    'Could not submit Pre-MOP Walk. Please try again.',
                    {
                        variant: 'error',
                        autoHideDuration: 4000
                    }
                );
            });
    };

    const toggleContacts = () => setContacts((contacts) => !contacts);

    return (
        <div className={styles.container}>
            {(!mop && !mopError) || loadingMOP ? (
                <LogoLoader />
            ) : mopError ? (
                <APIError
                    error={mopError}
                    defaultMessage={
                        'An error was encountered while retrieving the MOP.'
                    }
                    defaultTitle={'Error encountered, please try again'}
                    actions={[
                        {
                            variant: 'text',
                            label: 'Back to MOPs',
                            icon: faArrowLeft,
                            linkTo: '/mop/forms'
                        }
                    ]}
                />
            ) : savedMOP ? (
                <div className={styles.savedContainer}>
                    <h3>Pre-MOP Walk Submitted</h3>
                    <p>
                        Thank you for submitting the Pre-MOP Walk. The MOP is
                        now ready to be executed.
                    </p>
                    <div className={styles.actions}>
                        <Button
                            icon={faArrowLeft}
                            label="Back to MOPs"
                            type="secondary"
                            linkTo="/mop/forms"
                        />
                        <Button
                            icon={faList}
                            label="Execute MOP"
                            linkTo={`/mop/execute/${match?.params?.id}`}
                        />
                    </div>
                </div>
            ) : mop?.walkSignature ? (
                <div className={styles.savedContainer}>
                    <h3>Pre-MOP Walk Submitted</h3>
                    <p>This MOP has already been walked.</p>
                    <Button
                        icon={faArrowLeft}
                        label="Back to MOPs"
                        type="secondary"
                        linkTo="/mop/forms"
                    />
                </div>
            ) : (
                <>
                    <div className={styles.header}>
                        <div className={styles.headerInnerContainer}>
                            <div className={styles.stepCount}>
                                <p>
                                    <span className={styles.finishedSteps}>
                                        {
                                            mopSteps.filter(
                                                (ms) => ms.completed
                                            ).length
                                        }
                                    </span>
                                    /{mopSteps.length} steps completed
                                </p>
                            </div>
                            <h4>Pre-MOP Walk</h4>
                            <div></div>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.contacts}>
                            <Button
                                icon={contacts ? faUserMinus : faUserPlus}
                                onClick={toggleContacts}
                                label={
                                    contacts ? 'Hide Contacts' : 'Show Contacts'
                                }
                                className={styles.contactsButton}
                            />
                            {contacts && (
                                <MOPContacts
                                    fields={mop?.mopContacts}
                                    readonly
                                />
                            )}
                        </div>
                        <div className={styles.bodyInnerContainer}>
                            {mopSteps?.map?.((ms) => (
                                <MOPStepCard
                                    step={ms}
                                    commentKey="preMopComments"
                                    descriptionKey="description"
                                    handleStepChecked={handleStepChecked}
                                    handleComments={handleStepComments}
                                />
                            ))}
                            {mopSteps.every((ms) => ms.completed) && (
                                <div className={styles.submitContainer}>
                                    {!mopSteps.every((ms) => ms.completed) && (
                                        <ErrorMessage error="All steps must be marked as completed before submitting." />
                                    )}
                                    <MOPSignature
                                        handleSave={handleSaveSignature}
                                        message="Please sign to certify all steps were walked and verified."
                                        loadingMessage="Submitting Pre-MOP Walk..."
                                        signature={{
                                            signature: signature,
                                            name: displayName
                                        }}
                                        disabled={
                                            !mopSteps.every(
                                                (ms) => ms.completed
                                            )
                                        }
                                        loading={loadingSubmit}
                                        submitted={savedMOP}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default WalkMOP;
