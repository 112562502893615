import Checkbox from '../../general/input/Checkbox';
import CheckboxGroup from '../../general/input/CheckboxGroup';
import InputLabel from '../../general/input/InputLabel';
import MOPContacts from './MOPContacts';
import MOPEquipment from './MOPEquipment';

import styles from '../../../styles/apps/mop/MOPGeneralView.module.scss';

const MOPGeneralView = ({ mop, label = "View MOP" }) => {
    return (
        <div className={styles.formContainer}>
            <div className={styles.formBody}>
                <div className={styles.section}>
                    <h2>{label}</h2>
                    <Checkbox
                        label="Backout Required"
                        checked={mop?.backoutRequired}
                        disabled
                    />
                    <InputLabel label="MOP Name">
                        <p>{mop?.name}</p>
                    </InputLabel>
                    <InputLabel label="Project">
                        <p>{mop?.job?.vistaJobDescription}</p>
                    </InputLabel>
                    <InputLabel label="Location">
                        <p>{mop?.location}</p>
                    </InputLabel>
                    <InputLabel label="General Contractor">
                        <p>{mop?.generalContractor}</p>
                    </InputLabel>
                    <InputLabel label="Owner">
                        <p>{mop?.owner}</p>
                    </InputLabel>
                    <InputLabel label="Job Number">
                        <p>{mop?.job?.vistaJobNumber}</p>
                    </InputLabel>
                    <InputLabel label="Author">
                        <p>{mop?.author?.displayName}</p>
                    </InputLabel>
                    <InputLabel label="GENERAL DESCRIPTION OF WORK TO BE PERFORMED">
                        <p>{mop?.generalDescription}</p>
                    </InputLabel>
                    <InputLabel label="SYSTEMS AFFECTED">
                        <p>{mop?.systemsAffected}</p>
                    </InputLabel>
                    <InputLabel label="RISK RATING (Check One)">
                        <CheckboxGroup
                            options={[
                                {
                                    key: 'Low',
                                    value: 'Low'
                                },
                                {
                                    key: 'Medium',
                                    value: 'Medium'
                                },
                                {
                                    key: 'High',
                                    value: 'High'
                                }
                            ]}
                            selected={mop?.riskRating}
                            orientation="column"
                            readOnly
                        />
                    </InputLabel>
                    <InputLabel label="TRADES INVOLVED">
                        <p>{mop?.tradesInvolved}</p>
                    </InputLabel>
                    <div className={styles.row}>
                        <InputLabel
                            label="PROPOSED START DATE AND TIME:"
                            style={{
                                container: {
                                    flex: 1
                                }
                            }}
                        >
                            <p>
                                {mop?.proposedStart &&
                                    new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }).format(new Date(mop?.proposedStart))}
                            </p>
                        </InputLabel>
                        <InputLabel
                            label="PROPOSED COMPLETION DATE AND TIME:"
                            style={{
                                container: {
                                    flex: 1
                                }
                            }}
                        >
                            <p>
                                {mop?.proposedEnd &&
                                    new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }).format(new Date(mop?.proposedEnd))}
                            </p>
                        </InputLabel>
                    </div>
                    <div className={styles.row}>
                        <InputLabel
                            label="REVISED START DATE AND TIME:"
                            style={{
                                container: {
                                    flex: 1
                                }
                            }}
                        >
                            <p>
                                {mop?.revisedStart
                                    ? new Intl.DateTimeFormat('en-US', {
                                          month: 'short',
                                          day: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      }).format(new Date(mop?.revisedStart))
                                    : 'No revisions'}
                            </p>
                        </InputLabel>
                        <InputLabel
                            label="REVISED COMPLETION DATE AND TIME:"
                            style={{
                                container: {
                                    flex: 1
                                }
                            }}
                        >
                            <p>
                                {mop?.revisedEnd
                                    ? new Intl.DateTimeFormat('en-US', {
                                          month: 'short',
                                          day: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      }).format(new Date(mop?.revisedEnd))
                                    : 'No revisions'}
                            </p>
                        </InputLabel>
                    </div>
                    <div className={styles.row}>
                        <InputLabel
                            label="ACTUAL START DATE AND TIME:"
                            style={{
                                container: {
                                    flex: 1
                                }
                            }}
                        >
                            <p>
                                {mop?.completedStart
                                    ? new Intl.DateTimeFormat('en-US', {
                                          month: 'short',
                                          day: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      }).format(new Date(mop?.completedStart))
                                    : 'Not Started'}
                            </p>
                        </InputLabel>
                        <InputLabel
                            label="ACTUAL COMPLETION DATE AND TIME:"
                            style={{
                                container: {
                                    flex: 1
                                }
                            }}
                        >
                            <p>
                                {mop?.completedEnd
                                    ? new Intl.DateTimeFormat('en-US', {
                                          month: 'short',
                                          day: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      }).format(new Date(mop?.completedEnd))
                                    : 'Not Completed'}
                            </p>
                        </InputLabel>
                    </div>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>MATERIALS AND METHODS</h2>
                    <InputLabel label="List any designated storage locations for tools and materials:">
                        <p>{mop?.designatedStorageLocation}</p>
                    </InputLabel>
                    <InputLabel label="List any designated equipment staging areas to be used during the installation:">
                        <p>{mop?.designatedStagingArea}</p>
                    </InputLabel>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>EQUIPMENT</h2>
                    <InputLabel label="List specific equipment that will be added, removed, or modified in this MOP:">
                        <p>{mop?.equipmentModifications}</p>
                    </InputLabel>
                    <InputLabel label="List specific equipment locations requiring protection and protection required:">
                        <p>{mop?.locationPPERequirements}</p>
                    </InputLabel>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>IMPACT</h2>
                    <InputLabel label="Identify the systems that may be affected by the work operations:">
                        <p>{mop?.impactedSystems}</p>
                    </InputLabel>
                    <InputLabel label="List any specific precautions required for the personal/equipment safety:">
                        <p>{mop?.safetyPrecautions}</p>
                    </InputLabel>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>SERVICE PROTECTION</h2>
                    <InputLabel
                        label="All action being taken to protect any mechanical/electrical equipment or spaces which are a source or power directly to or indirectly serving critical
            equipment should be listed here:"
                    >
                        <p>{mop?.serviceProtection}</p>
                    </InputLabel>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>CONTINGENCY PLAN</h2>
                    <InputLabel label="Provide the backup or contingency plan if needed:">
                        <p>{mop?.contingencyPlan}</p>
                    </InputLabel>

                    <InputLabel label="Pretask Planning Report">
                                <CheckboxGroup
                                    options={[
                                        {
                                            key: 'completed',
                                            value: 'Completed and Signed'
                                        }
                                    ]}
                                    selected={mop?.pretaskReportCompleted ? 'completed' : null}
                                    readOnly
                                />
                            </InputLabel>
                            <InputLabel label="Electrical Work Permit">
                                    <CheckboxGroup
                                        options={[
                                            {
                                                key: 'completed',
                                                value: 'Completed and Signed'
                                            },
                                            {
                                                key: 'not applicable',
                                                value: 'Not Applicable'
                                            }
                                        ]}
                                        selected={mop?.energizedPermitStatus}
                                        readOnly
                                    />
                                </InputLabel>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>CONTACT LIST</h2>
                    <MOPContacts fields={mop?.mopContacts} readonly />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>EQUIPMENT</h2>
                    <MOPEquipment fields={mop?.mopEquipment} readonly />
                </div>
                <div className={styles.section}>
                    <InputLabel label="Security Access:">
                        <p>{mop?.securityAccess}</p>
                    </InputLabel>
                    <InputLabel label="Comments:">
                        <p>{mop?.comments}</p>
                    </InputLabel>
                </div>
            </div>
        </div>
    );
};

export default MOPGeneralView;
