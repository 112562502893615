import InputLabel from '../../general/input/InputLabel';
import { TextTooltip } from '../../general/Tooltip';
import {
    faGripVertical,
    faPencil,
    faTrash,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/apps/mop/MOPStepEditCard.module.scss';

const MOPStepEditCard = ({
    field,
    index,
    handleEdit,
    handleDelete,
    dragHandleProps,
    readonly
}) => {
    return (
        <div className={styles.container}>
            {!readonly && (
                <div className={styles.dragHandle} {...(dragHandleProps ?? {})}>
                    <FontAwesomeIcon icon={faGripVertical} />
                </div>
            )}
            <p className={styles.index}>#{index + 1}</p>
            <div className={styles.body}>
                <div className={styles.header}>
                    <FontAwesomeIcon
                        icon={faUser}
                        color="var(--xlight-text-color)"
                    />
                    <p>{field.assignedTo}</p>
                </div>
                <div className={styles.descriptionContainer}>
                    <div className={styles.description}>
                        <InputLabel label="Procedure">
                            {field.description}
                        </InputLabel>
                    </div>
                    {field.backoutDescription && (
                        <div className={styles.description}>
                            <InputLabel label="Backout Procedure">
                                {field.backoutDescription}
                            </InputLabel>
                        </div>
                    )}
                </div>
                <div className={styles.commentsContainer}>
                    {field?.preMopComments && (
                        <InputLabel label="Pre-MOP Walk Comments">
                            {field?.preMopComments}
                        </InputLabel>
                    )}
                    {field?.mopComments && (
                        <InputLabel label="MOP Comments">
                            {field?.mopComments}
                        </InputLabel>
                    )}
                    {field?.backoutComments && (
                        <InputLabel label="Backout Comments">
                            {field?.backoutComments}
                        </InputLabel>
                    )}
                </div>
            </div>
            {readonly && (
                <div>
                    <img srcSet={`${field?.signature} 2x`} alt="" />
                </div>
            )}
            {!readonly && (
                <div className={styles.actions}>
                    <TextTooltip
                        tooltip="Edit"
                        hoverTrigger="always"
                        hoverDelay={600}
                    >
                        <FontAwesomeIcon icon={faPencil} onClick={handleEdit} />
                    </TextTooltip>
                    <TextTooltip
                        tooltip="Remove"
                        hoverTrigger="always"
                        hoverDelay={600}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={handleDelete}
                        />
                    </TextTooltip>
                </div>
            )}
        </div>
    );
};

export default MOPStepEditCard;
